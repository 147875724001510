.ant-modal.cwt-fullscreen-modal {
    top: 0;
    display: inline-block;
    padding-bottom: 0;
    text-align: left;
    vertical-align: middle;
    max-width: calc(100vw - 6rem) !important;
    max-height: calc(100vh - 4rem) !important;
    width: 100% !important;
    height: 100% !important;

    >.ant-modal-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        > .ant-modal-header,
        > .ant-modal-footer {
            flex-shrink: 0;
        }
        > .ant-modal-body {
            flex: 1;
            overflow: auto;
        }
    }
}

.ant-modal.cwt-large-modal {
    max-width: calc(100vw - 6rem) !important;
    width: 920px !important;

    >.ant-modal-content {
        max-height: calc(100vh - 4rem) !important;
        display: flex;
        flex-direction: column;
        > .ant-modal-header,
        > .ant-modal-footer {
            flex-shrink: 0;
        }
        > .ant-modal-body {
            flex: 1;
            min-height: 0;
            overflow: auto;
        }
    }
}

.ant-modal-footer .ant-btn {
    margin-top: 4px;
}