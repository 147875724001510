@use 'sass:math';
@use 'sass:color';

@import "../../../../node_modules/bootstrap/scss/functions";

$primary: #3b73c8;
$success: #21eb05;
$secondary: #8c8c8c;
$info: #3b73c8;
$warning: #edbe54;
$danger: #cd131c;
$light: #d9d9d9;
$dark: #262626;

$lead-font-weight: unset;
$spacer: 1rem;
$grid-gutter-width: 1rem;
$spacers: (
  0: 0,
  1: math.div($spacer, 3),
  2: math.div($spacer, 1.5),
  3: $spacer,
  4: math.div($spacer, 0.75),
  5: math.div($spacer, 0.6),
);

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/variables-dark";
@import "../../../../node_modules/bootstrap/scss/maps";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../../../node_modules/bootstrap/scss/root";
@import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/type";

// Helpers
@import "../../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../../node_modules/bootstrap/scss/utilities/api";