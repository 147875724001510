@media only screen and (max-width: 568px) {
    .ant-picker-panel-container{
        .ant-picker-panels {
            display: inline-flex;
            flex-wrap: wrap;
            direction: ltr;

            .ant-picker-panel:nth-child(1){
                .ant-picker-header-next-btn,.ant-picker-header-super-next-btn{
                    visibility: visible !important;
                }

            }

            .ant-picker-panel:nth-child(2){
                display: none
            }
        }
    }
  }